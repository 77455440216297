import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectReviewCompletedComponent } from '../project-review-completed/project-review-completed.component';
import { ReviewOrderSubmittedDialogComponent } from '../review-order-submitted-dialog/review-order-submitted-dialog.component';

@Component({
  selector: 'submit-for-review-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ProjectReviewCompletedComponent,
    ReviewOrderSubmittedDialogComponent,
  ],
  templateUrl: './submit-for-review-dialog.component.html',
  styleUrl: './submit-for-review-dialog.component.scss',
})
export class SubmitForReviewDialogComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() submitReview = new EventEmitter<String>();
  @Output() submitRevision = new EventEmitter<String>();

  @Input() documents: any = '';
  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() status: string = '';

  reviewCompletedActive: boolean = false;
  isReviewOrderSubmitted: boolean = false;

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  toggleReviewCompletedActiveModal() {
    this.reviewCompletedActive != this.reviewCompletedActive;
  }
  submit() {
    if (this.status === 'ACTIVE') {
      this.submitReview.emit();
    } else if (this.status === 'REVISION_REQUESTED') {
      this.submitRevision.emit();
    }
    this.closeDialog();
    this.toggleReviewOrderSubmittedModal();
  }
  toggleReviewOrderSubmittedModal() {
    this.isReviewOrderSubmitted = !this.isReviewOrderSubmitted;
  }
}
