<div class="proposal-details proposal-details--{{ type }}">
  <div class="proposal-details__header">
    <div class="proposal-details__header__nav">
      <div class="proposal-details__header__nav__backtrack">
        <button (click)="goBack()">
          <span class="material-icons">keyboard_arrow_left</span>Back
        </button>
        <div
          class="proposal-details__header__nav__backtrack__breadcrumbs"
          *ngIf="data[0]?.sites[0]?.proposal?.status != 'BID_LOST'"
        >
          <div
            [ngClass]="{
              active: activeBreadcrumb[0],
              complete:
                activeBreadcrumb[1] ||
                activeBreadcrumb[2] ||
                activeBreadcrumb[3] ||
                activeBreadcrumb[4]
            }"
            class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb"
          >
            <p>Invitation</p>
          </div>
          <span class="material-icons">keyboard_arrow_right</span>
          <div
            [ngClass]="{
              active: activeBreadcrumb[1],
              complete:
                activeBreadcrumb[2] ||
                activeBreadcrumb[3] ||
                activeBreadcrumb[4]
            }"
            class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb"
          >
            <p>Proposal</p>
          </div>
          <span class="material-icons">keyboard_arrow_right</span>
          <div
            [ngClass]="{
              active: activeBreadcrumb[2],
              complete: activeBreadcrumb[3] || activeBreadcrumb[4]
            }"
            class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb"
          >
            <p>Active</p>
          </div>
          <span class="material-icons">keyboard_arrow_right</span>
          <div
            [ngClass]="{
              active: activeBreadcrumb[3],
              complete: activeBreadcrumb[4]
            }"
            class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb"
          >
            <p>Pending Review</p>
          </div>
          <span class="material-icons">keyboard_arrow_right</span>
          <div
            [ngClass]="{ active: activeBreadcrumb[4], complete: false }"
            class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb"
          >
            <p>Order Complete</p>
          </div>
        </div>
        <div
          class="proposal-details__header__nav__backtrack__breadcrumbs"
          *ngIf="data[0]?.sites[0]?.proposal?.status === 'BID_LOST'"
        >
          <div
            [ngClass]="{
              active: activeBreadcrumb[1],
              complete: activeBreadcrumb[2]
            }"
            class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb"
          >
            <p>Proposal</p>
          </div>
          <span class="material-icons">keyboard_arrow_right</span>
          <div
            [ngClass]="{ active: activeBreadcrumb[2], complete: false }"
            class="proposal-details__header__nav__backtrack__breadcrumbs__breadcrumb"
          >
            <p>Closed</p>
          </div>
        </div>
      </div>
      <div class="proposal-details__header__nav__content">
        <div class="proposal-details__header__nav__content__status">
          <p class="proposal-details__header__nav__content__status__label">
            Status:
          </p>
          <ng-container *ngIf="type === 'invitation'">
            <p
              *ngIf="data[0]?.sites[0]?.proposal?.status === 'INVITATION'"
              class="status --ready"
            >
              Ready
            </p>
            <p
              class="status --pending"
              *ngIf="data[0]?.sites[0]?.proposal?.status === 'SUBMITTED'"
            >
              Pending
            </p>
            <p
              class="status --bid-lost"
              *ngIf="data[0]?.sites[0]?.proposal?.status === 'DECLINED'"
            >
              Closed
            </p>
          </ng-container>
          <ng-container *ngIf="type === 'proposal'">
            <p
              class="status --pending"
              *ngIf="
                data[0]?.sites[0]?.proposal?.status === 'PROPOSAL' ||
                data[0]?.sites[0]?.proposal?.status === 'SUBMITTED'
              "
            >
              Pending
            </p>
            <p
              class="status --bid-won"
              *ngIf="data[0]?.sites[0]?.proposal?.status === 'BID_WON'"
            >
              Awarded
            </p>
            <p
              class="status --bid-lost"
              *ngIf="data[0]?.sites[0]?.proposal?.status === 'BID_LOST'"
            >
              Closed
            </p>
            <p
              class="status --bid-lost"
              *ngIf="data[0]?.sites[0]?.proposal?.status === 'ACTIVE'"
            >
              Active
            </p>
            <p
              class="status --bid-lost"
              *ngIf="
                data[0]?.sites[0]?.proposal?.status === 'CANCELLED' ||
                data[0]?.sites[0]?.proposal?.status === 'DECLINED'
              "
            >
              Closed
            </p>
          </ng-container>
          <ng-container *ngIf="type === 'active'">
            <p class="status --active">Active</p>
            <p class="status --pending" *ngIf="data.reviewStatus === 'pending'">
              In Review
            </p>
          </ng-container>
        </div>
        <!-- <div class="proposal-details__header__nav__content__date" *ngIf="
            type ==='invitation' ||
            (type ==='proposal' && data[0]?.sites[0]?.proposal?.status ==='PROPOSAL')
          ">
          <p class="proposal-details__header__nav__content__date__label">
            Proposal Due Date
          </p>
          <p class="proposal-details__header__nav__content__date__content">
            {{ data[0]?.sites[0]?.site?.bidDueDate|date:'MM/dd/yyyy hh:mm:ss a'}}
          </p>
        </div>commented bases on Rainier comments https://escreenlogic.atlassian.net/browse/CON-75 -->
      </div>
    </div>
    <div class="proposal-details__header__info">
      <h1>{{ address.streetAddress }}<br />{{ address.cityStateZip }}</h1>
      <div class="proposal-details__header__info__details">
        <div class="proposal-details__header__info__details__item">
          <p class="proposal-details__header__info__details__item__label">
            Project
          </p>
          <p class="proposal-details__header__info__details__item__text">
            {{ data[0]?.projectName }}
          </p>
        </div>
        <div class="proposal-details__header__info__details__item">
          <p class="proposal-details__header__info__details__item__label">
            Order ID
          </p>
          <p class="proposal-details__header__info__details__item__text">
            {{ data[0]?.projectOrderId }}
          </p>
        </div>
        <div class="proposal-details__header__info__details__item">
          <p class="proposal-details__header__info__details__item__label">
            Requested Turn Time
          </p>
          <p class="proposal-details__header__info__details__item__text">
            {{ data[0]?.sites[0]?.site?.proposedTurnTime ?? "--" }} Days
          </p>
        </div>
      </div>
    </div>
    <div class="proposal-details__header__tabs">
      <button
        (click)="toggleTab('rfp-info')"
        [class.active]="activeTab === 'rfp-info'"
      >
        <span class="material-icons">info</span>RFP Info
      </button>
      <!-- future implementation-->
      <!-- <button
        (click)="toggleTab('messages')"
        [class.active]="activeTab ==='messages'"
      >
        <span class="material-icons">notifications</span>Messages
      </button> -->
    </div>
  </div>
  <div class="proposal-details__content">
    <div
      class="proposal-details__content__rfp"
      *ngIf="activeTab === 'rfp-info'"
    >
      <div class="proposal-details__content__rfp__block">
        <h2>Site Data</h2>
        <div class="proposal-details__content__rfp__block__container">
          <div class="proposal-details__content__rfp__block__image">
            <p class="proposal-details__content__rfp__block__image__label">
              Site Map
            </p>
            <google-map
              height="500px"
              width="100%"
              [center]="center"
              [zoom]="zoom"
              [options]="mapOptions"
              ><map-polygon
                [paths]="polygonPaths"
                [options]="polygonOptions"
              ></map-polygon
            ></google-map>
          </div>
          <div class="proposal-details__content__rfp__block__detail">
            <div class="proposal-details__content__rfp__block">
              <div class="row">
                <div class="column">
                  <div
                    class="proposal-details__content__rfp__block__info__item"
                  >
                    <p
                      class="proposal-details__content__rfp__block__info__item__label"
                    >
                      Acres
                    </p>
                    <p
                      class="proposal-details__content__rfp__block__info__item__content"
                    >
                      {{ data[0]?.sites[0]?.site?.totalAcreage }} Acres
                    </p>
                  </div>
                  <div
                    class="proposal-details__content__rfp__block__info__item"
                  >
                    <p
                      class="proposal-details__content__rfp__block__info__item__label"
                    >
                      Table A
                    </p>
                    <p
                      class="proposal-details__content__rfp__block__info__item__content"
                    >
                      {{ tableA }}
                    </p>
                  </div>
                  <div
                    class="proposal-details__content__rfp__block__info__item"
                  >
                    <p
                      class="proposal-details__content__rfp__block__info__item__label"
                    >
                      Item 20
                    </p>
                    <p
                      class="proposal-details__content__rfp__block__info__item__content"
                    >
                      {{ item20 ? item20 : "N/A" }}
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div
                    class="proposal-details__content__rfp__block__info__item"
                  >
                    <p
                      class="proposal-details__content__rfp__block__info__item__label"
                    >
                      Land Use
                    </p>
                    <p
                      class="proposal-details__content__rfp__block__info__item__content"
                    >
                      {{ data[0]?.sites[0]?.site?.landUse }}
                    </p>
                  </div>
                  <div
                    class="proposal-details__content__rfp__block__info__item"
                  >
                    <p
                      class="proposal-details__content__rfp__block__info__item__label"
                    >
                      Site Notes
                    </p>
                    <p
                      class="proposal-details__content__rfp__block__info__item__content"
                    >
                      {{ data[0]?.sites[0]?.site?.siteNotes }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="proposal-details__content__rfp__block"
              *ngIf="documents?.length > 0"
            >
              <h3>RFP Documents</h3>
              <div class="proposal-details__content__rfp__block__container">
                <div class="proposal-details__content__rfp__block__documents">
                  <div
                    class="proposal-details__content__rfp__block__documents__item"
                    *ngFor="let document of documents"
                  >
                    <div
                      class="proposal-details__content__rfp__block__documents__item__content"
                    >
                      <p
                        class="proposal-details__content__rfp__block__documents__item__content__title"
                      >
                        {{ document?.fileName }}
                      </p>
                      @switch (document.type) { @case ('PROPOSAL') {
                      <p
                        class="proposal-details__content__rfp__block__documents__item__content__description"
                      >
                        Proposal
                      </p>
                      } @case ('SITE') {
                      <p
                        class="proposal-details__content__rfp__block__documents__item__content__description"
                      >
                        Site
                      </p>
                      } @case ('DELIVERABLE') {
                      <p
                        class="proposal-details__content__rfp__block__documents__item__content__description"
                      >
                        Deliverable
                      </p>
                      } @case ('REVISION') {
                      <p
                        class="proposal-details__content__rfp__block__documents__item__content__description"
                      >
                        Revision
                      </p>
                      }@case ('ATP') {
                      <p
                        class="proposal-details__content__rfp__block__documents__item__content__description"
                      >
                        ATP
                      </p>
                      }}
                    </div>
                    <div class="icon">
                      <span
                        *ngIf="document.type !== 'SURVEY_CHECKLIST'"
                        class="material-icons"
                        (click)="downloadDocument(document)"
                        >download</span
                      >
                      <a
                        *ngIf="document.type === 'SURVEY_CHECKLIST'"
                        href="{{ document.documentPath }}"
                        target="_blank"
                        [ngStyle]="{ color: 'black' }"
                      >
                        <span class="material-icons">download</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="proposal-details__content__messages"
      *ngIf="activeTab === 'messages'"
    >
      <div class="proposal-details__content__messages__conversations">
        <p
          *ngFor="let conversation of data.conversations"
          [class.active]="conversation.id === currentConversation"
          (click)="changeConversation(conversation.id)"
        >
          {{ conversation.recipient
          }}<span class="material-icons">keyboard_arrow_right</span>
        </p>
      </div>
      <ng-container *ngFor="let conversation of data.conversations">
        <ng-container *ngIf="conversation.id === currentConversation">
          <div class="proposal-details__content__messages__current">
            <button
              class="proposal-details__content__messages__current__send"
              (click)="sendMessage()"
            >
              Send
            </button>
            <textarea
              class="proposal-details__content__messages__current__new"
              placeholder="New Message"
            ></textarea>
            <div class="proposal-details__content__messages__current__list">
              <div
                *ngFor="let message of conversation.messages"
                class="proposal-details__content__messages__current__list__message"
                [class.received]="!message.sender"
              >
                <div
                  class="proposal-details__content__messages__current__list__message__meta"
                >
                  <p
                    class="proposal-details__content__messages__current__list__message__meta__date"
                  >
                    {{ message.date }}
                  </p>
                  <p
                    class="proposal-details__content__messages__current__list__message__meta__sender"
                  >
                    {{ message.sender ? "You" : conversation.recipient }}
                  </p>
                </div>
                <div
                  class="proposal-details__content__messages__current__list__message__content"
                >
                  <p>{{ message.message }}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="proposal-details__footer">
    <div class="proposal-details__footer__left">
      <p class="proposal-details__footer__service-type">Service Type</p>
      <h3 class="proposal-details__footer__service-type-title">
        {{ data[0]?.sites[0]?.site?.siteType }}
      </h3>
    </div>
    <div class="proposal-details__footer__right">
      <button
        class="proposal-details__footer__right__documents"
        *ngIf="
          data[0]?.sites[0]?.proposal?.status !== 'lost-bid' ||
          type === 'active'
        "
        (click)="toggleDocumentsModal()"
      >
        <span class="material-icons">upload</span>
        <p>Documents</p>
      </button>
      <button
        class="proposal-details__footer__right__documents"
        (click)="toggleNotesModal()"
      >
        <span class="material-icons">notes</span>
        <p>Notes</p>
      </button>
      <div
        class="proposal-details__footer__right__update-details"
        *ngIf="
          type === 'invitation' ||
          data[0]?.sites[0]?.proposal?.status === 'SUBMITTED'
        "
      >
        <div class="proposal-details__footer__right__update-details__turn-time">
          <p>Turn Time</p>
          <div
            class="proposal-details__footer__right__update-details__turn-time__input"
          >
            <div *ngIf="type === 'invitation'">
              <input
                id="proposalTurnTime"
                type="number"
                placeholder="Days"
                [(ngModel)]="turnTime"
                (blur)="saveTurnTime()"
              />
            </div>
            <div *ngIf="type === 'proposal'">
              <input
                id="proposalTurnTime"
                type="number"
                placeholder="Days"
                [(ngModel)]="turnTime"
              />
            </div>
          </div>
        </div>
        <div
          class="proposal-details__footer__right__update-details__bid-amount"
        >
          <p>Bid Amount</p>
          <div
            class="proposal-details__footer__right__update-details__bid-amount__input"
          >
            <div *ngIf="type === 'invitation'">
              <input
                id="proposalBidAmount"
                type="number"
                placeholder="$"
                [(ngModel)]="bidAmount"
                (blur)="saveBidAmount()"
              />
            </div>
            <div *ngIf="type === 'proposal'">
              <input
                id="proposalBidAmount"
                type="number"
                placeholder="$"
                [(ngModel)]="bidAmount"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        class="proposal-details__footer__right__button-dark"
        *ngIf="
          type === 'invitation' &&
          data[0]?.sites[0]?.proposal?.status !== 'SUBMITTED'
        "
        (click)="submitBid()"
        [disabled]="submitBidDisabled"
      >
        Submit Bid
      </button>
      <button
        class="proposal-details__footer__right__button-dark"
        *ngIf="
          (type === 'invitation' || type === 'proposal') &&
          data[0]?.sites[0]?.proposal?.status === 'SUBMITTED'
        "
        (click)="saveBid()"
      >
        Save Bid
      </button>
      <button
        class="proposal-details__footer__right__button-light"
        *ngIf="
          (type === 'invitation' &&
            data[0]?.sites[0]?.proposal?.status === 'INVITATION') ||
          (type === 'proposal' &&
            data[0]?.sites[0]?.proposal?.status === 'SUBMITTED')
        "
        (click)="toggleDeclineModal()"
      >
        Decline
      </button>
      <div
        class="proposal-details__footer__right__details"
        *ngIf="
          type !== 'invitation' &&
          !(
            type === 'proposal' &&
            data[0]?.sites[0]?.proposal?.status === 'SUBMITTED'
          )
        "
      >
        <div class="proposal-details__footer__right__details__turn-time">
          <p class="proposal-details__footer__right__details__turn-time__label">
            Turn Time
          </p>
          <p
            class="proposal-details__footer__right__details__turn-time__content"
          >
            {{ data[0]?.sites[0]?.proposal?.proposedTurnTime }} Days
          </p>
        </div>
        <div class="proposal-details__footer__right__details__bid-amount">
          <p
            class="proposal-details__footer__right__details__bid-amount__label"
          >
            Bid Amount
          </p>
          <p
            class="proposal-details__footer__right__details__bid-amount__content"
          >
            ${{ data[0]?.sites[0]?.proposal?.bid }}
          </p>
        </div>
      </div>
      <button
        class="proposal-details__footer__right__button-won"
        *ngIf="
          type === 'proposal' &&
          data[0]?.sites[0]?.proposal?.status === 'BID_WON'
        "
        (click)="generateATP()"
      >
        Sign ATP
      </button>
      <p
        class="proposal-details__footer__right__pending"
        *ngIf="
          !showReviewSubmission &&
          data[0]?.sites[0]?.proposal?.status === 'ACTIVE'
        "
      >
        Require Deliverable Document for submission
      </p>
      <button
        *ngIf="data[0]?.sites[0]?.proposal?.status === 'ACTIVE'"
        disabled="showReviewSubmission"
        class="proposal-details__footer__right__button-dark"
        (click)="submitForReview()"
      >
        Submit Review
      </button>
      <button
        *ngIf="data[0]?.sites[0]?.proposal?.status === 'REVISION_REQUESTED'"
        class="proposal-details__footer__right__button-dark"
        (click)="submitForReview()"
      >
        Submit Revision
      </button>
      <p
        class="proposal-details__footer__right__pending"
        *ngIf="data[0]?.sites[0]?.proposal?.status === 'REVIEW_PENDING'"
      >
        Review Pending
      </p>
      <p
        class="proposal-details__footer__right__pending"
        *ngIf="data[0]?.sites[0]?.proposal?.status === 'REVISION_PENDING'"
      >
        Revision Pending
      </p>
    </div>
  </div>
</div>

<documents-dialog
  [data]="data[0]?.sites[0]"
  [active]="documentsActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  (update)="updateDocuments($event)"
  (modalClosed)="toggleDocumentsModal()"
  [ngClass]="{ 'documents-dialog--active': documentsActive }"
></documents-dialog>

<notes-dialog
  [active]="notesActive"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleNotesModal()"
  [ngClass]="{ 'documents-dialog--active': notesActive }"
  [bidNotes]="bidNotes"
  [data]="data[0]?.sites[0]"
  (saveBidNotes)="saveBidNote($event)"
></notes-dialog>

<bid-dialog
  [active]="bidActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  [data]="data[0]"
  (modalClosed)="toggleSubmitBidModal()"
  [ngClass]="{ 'documents-dialog--active': bidActive }"
></bid-dialog>

<decline-dialog
  [active]="isDeclineActive"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleDeclineModal()"
  (decline)="decline($event)"
  [ngClass]="{ 'documents-dialog--active': isDeclineActive }"
></decline-dialog>

<decline-success
  [active]="isDeclineSuccessActive"
  [address]="address"
  [siteType]="siteType"
  (modalClosed)="toggleDeclineSucessModal()"
  [ngClass]="{ 'documents-dialog--active': isDeclineSuccessActive }"
></decline-success>

<bid-saved-dialog
  [active]="savedActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleSaveModal()"
  [ngClass]="{ 'documents-dialog--active': savedActive }"
></bid-saved-dialog>

<signatp-dialog
  [atpDocumentPath]="atpDocumentPath"
  [active]="signAtpActive"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleATPModal()"
  [ngClass]="{ 'documents-dialog--active': signAtpActive }"
  [modalHeaderTitle]="childModalHeaderTitle"
  [modalBody]="childModalBody"
  (signedAtpEvent)="signATP($event)"
  (declineAtpEvent)="declineATP($event)"
></signatp-dialog>

<atp-sign-accepted-dialog
  [active]="isATPSignedAccepted"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleAtpAcceptedModal()"
  [ngClass]="{ 'documents-dialog--active': isATPSignedAccepted }"
  [modalHeaderTitle]="childModalHeaderTitle"
  [modalBody]="childModalBody"
></atp-sign-accepted-dialog>

<submit-for-review-dialog
  [active]="submitForReviewActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  [status]="data[0]?.sites[0]?.proposal?.status"
  (modalClosed)="toggleSubmitForReviewModal()"
  (submitReview)="submitReview()"
  (submitRevision)="submitRevision()"
  [ngClass]="{ 'documents-dialog--active': submitForReviewActive }"
></submit-for-review-dialog>
