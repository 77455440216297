<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>
      Submit This Order For {{ status === "ACTIVE" ? "Review" : "Revision" }}?
    </h2>
    <p>
      This action will send this project for
      {{ status === "ACTIVE" ? "Review" : "Revision" }}.
      <br />
      <br />
      Do you wish to continue?
    </p>

    <br />
    <br />

    <div class="documents-dialog__content__footer">
      <div class="documents-dialog__content__footer__service-type">
        <div class="documents-dialog__content__footer__service-type__label">
          Service Type
        </div>
        <div class="service-type">{{ siteType }}</div>
      </div>
      <div class="documents-dialog__content__footer__service-type">
        <div class="documents-dialog__content__footer__service-type__label">
          Location
        </div>
        <div class="location">
          <span *ngIf="address.streetAddress"
            >{{ address.streetAddress }}<br /></span
          >{{ address.cityStateZip }}
        </div>
      </div>
      <div class="btnalign">
        <button
          class="documents-dialog__content__footer__submit pull-right"
          (click)="submit()"
        >
          Submit for {{ status === "ACTIVE" ? "Review" : "Revision" }}
        </button>
        <button class="defaultbtn" (click)="closeDialog()">Back</button>
      </div>
    </div>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>

<projectreview-completed
  [active]="reviewCompletedActive"
  (modalClosed)="toggleReviewCompletedActiveModal()"
  [ngClass]="{ 'documents-dialog--active': reviewCompletedActive }"
  [status]="status"
></projectreview-completed>

<review-order-submitted-dialog
  [active]="isReviewOrderSubmitted"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  [status]="status"
  (modalClosed)="toggleReviewOrderSubmittedModal()"
  [ngClass]="{ 'documents-dialog--active': isReviewOrderSubmitted }"
></review-order-submitted-dialog>
